import '../main.css' 

// Menu
import MENU from './modules/menu';
const menu = new MENU();

// Same Height
import SAMEHEIGHT from './modules/height';
const sameHeight = new SAMEHEIGHT();

// Login  
import LOGIN from './modules/login';
const login = new LOGIN();

// AOS
import AOS from './modules/aos';
const aos = new AOS();

// Fancyapps
import FANCYAPPS from './modules/fancyapps';
const fancyapps = new FANCYAPPS();

